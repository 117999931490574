.text_entry
  @apply bg-grey-light text-grey-dark border appearance-none w-full rounded py-3 px-3 leading-tight
  &:focus
    @apply outline-none bg-grey-lighter border-grey

.label
  @apply block text-grey-dark mb-2

.button
  @apply w-full p-3 bg-teal text-white rounded shadow

  
